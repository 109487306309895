<template>
  <div class="confirmed">
    <div class="confirmed-bg">
      <img src="../../assets/images/lecturer/下载邀请函@2x.png" alt="" />
    </div>
    <div class="confirmed-content">
      <div class="confirmed-img">
        <img src="@/assets/images/lecturer/对号2@2x.png" alt="" />
      </div>
      <div class="confirmed-title">
        您已接受本次邀请成为特邀嘉宾
      </div>
      <div class="confirmed-item">
        <div class="confirmed-label">
          <img src="@/assets/images/lecturer/视频@2x.png" alt="" />
          <span style="padding-left:8px">直播方式</span>
        </div>
        <div class="confirmed-app">·移动端</div>
        <div class="confirmed-item-1">
          <div class="confirmed-item-text">
            1.长按识别二维码，下载应用，微信登录并选择讲师身份登录进行直播。
          </div>
          <div class="confirmed-item-code">
            <img src="@/assets/images/lecturer/code.png" alt="" />
          </div>
        </div>
        <div class="confirmed-item-2">
          2.应用商店搜索“人单合一”APP下载，微信登录并选择。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.confirmed {
  background: #ccc url(../../assets/images/lecturer/邀请背景@3x.png) no-repeat
    fixed 0;
  background-size: cover;
  min-height: calc(100vh - 164px);
  width: 100%;
  z-index: -10;
  zoom: 1;

  .confirmed-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 50px;
    img {
      width: 800px;
    }
  }

  .confirmed-content {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;

    .confirmed-img {
      position: absolute;
      width: 100%;
      margin: auto;
      text-align: center;
      padding-top: 270px;
      img {
        width: 60px;
      }
    }
    .confirmed-title {
      margin: auto;
      padding-top: 349px;
      width: 176px;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      color: #1f2e4d;
      opacity: 1;
    }
  }

  .confirmed-item {
    margin: auto;
    padding-top: 48px;
    text-align: left;
    width: 350px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;

    .confirmed-label {
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      line-height: 14px;
      color: #ffffff;
      opacity: 1;
      img {
        width: 20px;
      }
    }
    .confirmed-app {
      text-align: left;
      padding-top: 15px;
    }
    .confirmed-item-1 {
      padding-top: 20px;
      display: flex;

      .confirmed-item-code img {
        width: 100px;
      }
    }
  }
}
</style>
