<template>
  <div class="used">
    <img src="../../assets/images/lecturer/感叹号@2x.png" alt="" />
    <div class="text">
      该邀请链接已经被别的讲师接受，您可以联系店铺重新发送讲师邀请链接。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.used {
  background: #ccc url(../../assets/images/lecturer/邀请背景@3x.png) no-repeat
    fixed 0;
  background-size: cover;
  min-height: calc(100vh - 164px);
  width: 100%;
  z-index: -10;
  zoom: 1;
  text-align: center;

  img {
    padding-top: 300px;
    width: 80px;
  }

  .text {
    margin: auto;
    padding-top: 25px;
    width: 350px;
    font-size: 20px;
    font-weight: 400;
    color: #1f2e4d;
    line-height: 40px;
  }
}
</style>
